import React, { useState } from "react";
import "./styles/InputField.css";
import "./styles/TextAreaInput.css";

export default function TextAreaInput({ placeHolder="", setFieldValue, value }) {
  const [characterCount, setCharacterCount] = useState(placeHolder.length);

  const handleChange = (event) => {
    setCharacterCount(event.target.value.length);
    setFieldValue("description", event.target.value);
  };

  return (
    <div className="input-container">
      <textarea
        placeholder={placeHolder}
        className="input-field"
        onChange={handleChange}
        value={value}
      />
      <span className="character-count">{characterCount}</span>
    </div>
  );
}