import React from "react";
import "../styles/App.css";
import tbord from "../assets/new_images/SquaresFour.svg";
import playm from "../assets/new_images/youmusic.svg";
import posts from "../assets/new_images/posts.svg";
import {
  Albums,
  MusicalNotes,
  Cash,
  GridSharp,
} from "react-ionicons";
import translate from "../i18n/translate";

const h = "20px";

const SidebarItems = [
  {
    id: 1,
    name: translate("dash"),
    location: "/app/dashboard",
    user: "artist",
    icon: tbord,
  },
  {
    id: 2,
    name: translate("yourmusic"),
    location: "/app/audio",
    user: "artist",
    icon: playm,
  },
  // {
  //   id: 3,
  //   name: translate("publication"),
  //   location: "/app/albums",
  //   user: "artist",
  //   icon: pub,
  // },
  // {
  //   id: 4,
  //   name: translate("marketing"),
  //   location: "/app/albums",
  //   user: "artist",
  //   icon: market,
  // },
  // {
  //   id: 5,
  //   name: translate("incomes"),
  //   location: "/app/royalties",
  //   user: "artist",
  //   icon: coin,
  // },
  {
    id: 6,
    name: translate("dash"),
    location: "/app/dashboardPodcast",
    user: "podcast",
    icon: <GridSharp className="mtion" height={h} width={h} />,
  },
  {
    id: 7,
    name: "Episodes",
    location: "/app/episode",
    user: "podcast",
    icon: <MusicalNotes className="mtion" height={h} width={h} />,
  },
  {
    id: 8,
    name: "Tableau de bord",
    location: "/app/dashboardLabel",
    user: "label",
    icon: <GridSharp className="mtion" height={h} width={h} />,
  },
  {
    id: 9,
    name: "Artists",
    location: "/app/label/artists",
    user: "label",
    icon: <MusicalNotes className="mtion" height={h} width={h} />,
  },
  {
    id: 10,
    name: "Podcasts",
    location: "/app/podcasts",
    user: "podcast",
    icon: <Albums className="mtion" height={h} width={h} />,
  },
  {
    id: 11,
    name: "Redevances",
    location: "/app/PodcastRoyalties",
    user: "podcast",
    icon: <Cash className="mtion" height={h} width={h}/>,
  },
  {
    id: 12,
    name: "Publications",
    location: "/app/posts",
    user: "artist",
    icon: posts,
  }
];

export default SidebarItems;
