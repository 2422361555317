import React, { useEffect, useState } from "react";
import "../../pages/App.css";
import { I18nPropvider, LOCALES } from "../../i18n";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import useUser from "../../hooks/useUser";
import ReactGA from "react-ga";
import NavbarMobileMenu from "./components/NavbarMobileMenu";
import DeviceContainer from "./components/DeviceContainer";
import WhySectionComponent from "./components/WhySectionComponent";
import PremiumContainer from "./components/PremiumContainer";
import {useLanguage} from "../../datamanager/contexts/Language";

function Home() {
  const { language, languageList, updateHotLanguage } = useLanguage();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useUser("home")

  return (
    <I18nPropvider locale={language}>
      <section className="bodyy">
        <Navbar />

        <NavbarMobileMenu />

        <DeviceContainer />

        <WhySectionComponent />

        <PremiumContainer />

        {/*<EventContainer />*/}

        <Footer />
      </section>
    </I18nPropvider>
  );
}

export default Home;
