import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import "../../../styles/App.css";
import "../../../styles/publish.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import moment from "moment";
import "moment/locale/fr";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import BackErr from "./components/BackErr";
import Uploaded from "./components/Uploaded";
import submitAudio from "./components/submitAudio";
import { SignUpSchema } from "./components/SignUpSchema";
import { InitialValues } from "./components/InitialValues";
import useFetchAlbumCategories from "../../../hooks/useFetchAlbumCategories";
import { useBackErr } from "../../../datamanager/contexts/locals/publishAudio/backErr";
import { useSubmitting } from "../../../datamanager/contexts/locals/publishAudio/isSubmitting";
import { useUploadPercentage } from "../../../datamanager/contexts/locals/publishAudio/uploadPercent";
import { useUploadSpeed } from "../../../datamanager/contexts/locals/publishAudio/uploadSpeed";
import { useConnected } from "../../../datamanager/contexts/locals/publishAudio/connected";
import {BaseFormAPICall} from "./components/BaseFormAPICall";
import {useMetaData} from "../../../datamanager/contexts/locals/publishAudio/metaData";
import {useMsg} from "../../../datamanager/contexts/locals/publishAudio/msg";
import FormPagesSingle from "./components/FormPagesSingle";
import {useFinishedUpload} from "../../../datamanager/contexts/locals/publishAudio/finishedUpload";
import {useSelectedAudio} from "../../../datamanager/contexts/locals/publishAudio/selectedAudio";
import {useSelectedImage} from "../../../datamanager/contexts/locals/publishAudio/selectedImage";
import {sleep} from "../../../utils/functions";
import axios from "axios";
import ModalCore from "../../../components/modals/ModalCore";
import ModalContext from "../../../datamanager/contexts/modalContext";
moment.locale("fr");
registerLocale("en", en);


function PublishAudio() {

  useFetchAlbumCategories();

  // Get data from the global state
  const { currentUser: user } = useContext(CurrentUserContext);
  const { backErr, handleSetBackErr } = useBackErr();
  const { connected, handleSetConnected } = useConnected();
  const { setIsSubmitting: handleSetSubmitting, isSubmitting } = useSubmitting();
  const { handleSetUploadPercentage, uploadPercentage } = useUploadPercentage();
  const { handleSetUploadSpeed, uploadSpeed} = useUploadSpeed();
  const { metaData, handleSetMetaData, handleClearMetaData } = useMetaData();
  const { isFinishedUpload, setIsFinishedUpload: handleSetIsFinishedUpload } = useFinishedUpload();
  const { handleSetMsg } = useMsg();
  const { selectedAudio, handleSetSelectedAudio } = useSelectedAudio();
  const { open, closeModal } = useContext(ModalContext);
  const { handleSetSelectedImage } = useSelectedImage();

  const { musicApi, AxiosConfig } = BaseFormAPICall(
    handleSetUploadPercentage,
    handleSetSubmitting,
    handleSetUploadSpeed,
    handleSetConnected
  );

  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const handleSubmit = (values, { resetForm }) => {
    submitAudio(
      values,
      handleSetSubmitting,
      handleSetUploadPercentage,
      handleSetUploadSpeed,
      handleSetBackErr,
      handleSetMsg,
      handleSetConnected,
      metaData,
      musicApi,
      AxiosConfig,
      user,
      selectedAudio,
      controller,
      source,
      handleSetIsFinishedUpload,
      isFinishedUpload
    )
    //   .then(r => { sleep(10000).then(() => {
    //   document.location = "/app/audio";
    //   sleep(1000).then(() => {
    //     resetForm();
    //     handleSetUploadPercentage(0);
    //     handleSetUploadSpeed(0);
    //     handleSetSelectedAudio(null);
    //     handleSetSelectedImage(null);
    //     handleClearMetaData();
    //     handleSetBackErr(false);
    //     handleSetMsg(null);
    //   })
    // }); })
    ;
  };
  return (
    <div>
      {
        user ? (
          <Formik
            initialValues={InitialValues}
            validationSchema={SignUpSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
              errors,
              values,
              setFieldValue,
              handleSubmit,
              handleChange,
              resetForm,
              isValid
            }) => (
              <form>
                <FormPagesSingle
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleReset={resetForm}
                    isFinishedUpload={isFinishedUpload}
                    uploadPercentage = {uploadPercentage}
                    isValid={isValid}
                    uploadSpeed = {uploadSpeed}
                    handleSetUploadPercentage = {handleSetUploadPercentage}
                    handleSetUploadSpeed = {handleSetUploadSpeed}
                    handleSetSelectedAudio = {handleSetSelectedAudio}
                    handleSetSelectedImage = {handleSetSelectedImage}
                    handleSetMetaData = {handleSetMetaData}
                    handleSetBackErr = {handleSetBackErr}
                    handleSetMsg = {handleSetMsg}
                    controller = {controller}
                    source = {source}
                    handleSetFinishedUpload = {handleSetIsFinishedUpload}
                    resetForm = {resetForm}
                    handleSetConnected = {handleSetConnected}
                    handleSetSubmitting = {handleSetSubmitting}
                    selectedAudio = {selectedAudio}
                    user = {user}
                    AxiosConfig = {AxiosConfig}
                    musicApi = {musicApi}
                    metaData = {metaData}
                    backErr = {backErr}
                    isSubmitting = {isSubmitting}
                  />
              </form>
            )}
          </Formik>
        ) : <Navigate to="/app/dashboard" />
      }
      <ModalCore open={open} onClose={closeModal} />
    </div>
  );
}

export default PublishAudio;