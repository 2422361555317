import React from "react";
import {useCurrentPage} from "../../../../datamanager/contexts/locals/publishAudio/currentPage";
import StepperActionsLastSingle from "./StepperActionsLastSingle";
import StepperActionsIntermediairySingle from "./StepperActionsIntermediairySingle";

export default function StepperActionsSingle({ values, errors, handleSubmit, isValid, handleReset,
                                         handleSetUploadPercentage, uploadPercentage = null, controller = null, source = null,
                                         handleSetMsg = null, handleSetBackErr = null, handleSetConnected = null, handleSetUploadSpeed = null,
                                         handleSetSubmitting = null, selectedAudio = null, user = null, AxiosConfig = null, musicApi = null, metaData = null,
                                         handleSetFinishedUpload = null, resetForm = null
                                       }) {

  const { currentPage } = useCurrentPage();

  if (currentPage !== 3) {
    return <StepperActionsIntermediairySingle  values={values} errors={errors} isValid = {isValid} />;
  } else {
    if(uploadPercentage !== null) {
      return <StepperActionsLastSingle handleSubmit={handleSubmit} handleReset={handleReset}
                                 handleSetUploadPercentage={handleSetUploadPercentage} uploadPercentage={uploadPercentage} controller={controller}
                                 source = {source} values={values} AxiosConfig={AxiosConfig} musicApi={musicApi} metaData={metaData}
                                 handleSetMsg={handleSetMsg} handleSetBackErr={handleSetBackErr} handleSetConnected={handleSetConnected}
                                 handleSetUploadSpeed={handleSetUploadSpeed} handleSetSubmitting={handleSetSubmitting} selectedAudio={selectedAudio}
                                 user={user} handleSetFinishedUpload={handleSetFinishedUpload} resetForm={resetForm}
      />
    }else{
      return <StepperActionsLastSingle handleSubmit={handleSubmit} handleReset={handleReset} />;
    }
  }
}