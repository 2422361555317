import style from '../styles/components/SearchBar.module.css';
import { SearchSharp } from "@mui/icons-material";

export default function SearchBar({ searchPlaceholder }){
  return (
    <div className={style.searchbar}>
      <span className={style.searchplaceholder}>{searchPlaceholder}</span>
      <SearchSharp className={style.searchicon} />
    </div>
  );
}