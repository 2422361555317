import translate from '../../../i18n/translate'
import '../../App.css';
import styles from '../styles/premium.module.css'
import pp1 from '../../../assets/new_images/pp1.svg'
import pp2 from '../../../assets/new_images/pp2.svg'
import pp3 from '../../../assets/new_images/pp3.svg'
import arrow_down from "../../../assets/new_images/arrow_down.svg";

const PremiumContainer = () => {

    return (
      <div className={`premium-cta ${styles.premiumContainer}`}>
          <div className="fond3"></div>
          <div className="fond5"></div>
        <div className={`why-head ${styles.whyHeader}`}>
          <span className='why-titlee'>{translate("suppp")}</span>
        </div>

        <div className={`why-head ${styles.whyHeader}`}>
          <h3 className='hero-subtitlee2'>{translate("subsuppp")}</h3>
        </div>

          <div className="carous">
              <div className="carous-elt1">
                  <img className="carousimg" src={pp1}/>
                  <span className="carousspan">Sandrine Nanga</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp2}/>
                  <span className="carousspan">Ko-C</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp3}/>
                  <span className="carousspan">Dashor</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp1}/>
                  <span className="carousspan">Sandrine Nanga</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp2}/>
                  <span className="carousspan">Ko-C</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp3}/>
                  <span className="carousspan">Dashor</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp3}/>
                  <span className="carousspan">Mimie</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp2}/>
                  <span className="carousspan">H3riQ</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp1}/>
                  <span className="carousspan">Kill B</span>
              </div>

              <div className="carous-elt1">
                  <img className="carousimg" src={pp3}/>
                  <span className="carousspan">Mimie</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp2}/>
                  <span className="carousspan">H3riQ</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp1}/>
                  <span className="carousspan">Sandrine Nanga</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp2}/>
                  <span className="carousspan">Ko-C</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp3}/>
                  <span className="carousspan">Dashor</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp1}/>
                  <span className="carousspan">Sandrine Nanga</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp2}/>
                  <span className="carousspan">Ko-C</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp3}/>
                  <span className="carousspan">Dashor</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp3}/>
                  <span className="carousspan">Mimie</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp2}/>
                  <span className="carousspan">H3riQ</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp1}/>
                  <span className="carousspan">Kill B</span>
              </div>

              <div className="carous-elt1">
                  <img className="carousimg" src={pp3}/>
                  <span className="carousspan">Mimie</span>
              </div>
              <div className="carous-elt1">
                  <img className="carousimg" src={pp2}/>
                  <span className="carousspan">H3riQ</span>
              </div>
          </div>

          <div className="carous3">
              <button className="btn-disc3"><span className="btntext2">{translate("getprem")}</span><img
                className="imgbtn"
                src={arrow_down}/>
              </button>
          </div>

          <div className={`why-head ${styles.whyHeader}`}>
            <span className='why-title3'>{translate("knowmore")}
            </span>
          </div>

          <div className={`why-head ${styles.whyHeader}`}>
              <h3 className='hero-subtitlee6'>{translate("conta")}</h3>
              <button className="btn-disc2"><span className="btntext3">{translate("contactus")}</span></button>
          </div>

          <div className={`why-head ${styles.whyHeader}`}>
              <h3 className='hero-subtitle6'>{translate(" ")}</h3>
          </div>

      </div>
    )
}

export default PremiumContainer