import translate from '../../../i18n/translate'
import '../../App.css';
import styles from '../styles/device.module.css'
import arrow_up from "../../../assets/new_images/arrow_up.svg";
import arrow_down from "../../../assets/new_images/arrow_down.svg";
import cornerlight from "../../../assets/new_images/cornerlight.svg"
import { HashLink as Linka } from 'react-router-hash-link';

const DeviceContainer = () => {


  return (
    <div className={`device-grid-container ${styles.deviceContainer}`}>
      <div className="main-row">
        <div className="device-grid">
          <h2 className='hero-title'>{translate("value")}</h2>
          <h2 className='hero-title'>{translate("value1")}</h2>
          <h3 className='hero-subtitle'>{translate("covalue")}</h3>
          <div className="row">
            <Linka to="/app/access">
              <button onClick={() => {}} className="btn-freedown"><span
                className="btntext">{translate("joincolart")}</span><img className="imgbtn" src={arrow_up}/></button>
            </Linka>
            <Linka to="#">
              <button onClick={() => {}} className="btn-disc"><span
                className="btntext">{translate("disc")}</span><img className="imgbtn" src={arrow_down}/></button>
            </Linka>
          </div>
        </div>
        <div className="first-box">
          <div className="cornerlight"></div>
        </div>
      </div>
      <div className="mob">
        <h2 className='hero-title'>{translate("value")}</h2>
        <h2 className='hero-title'>{translate("value1")}</h2>
        <h3 className='hero-subtitle'>{translate("covalue")}</h3>
        <div className="row">
          <a href="https://artists.colorfol.com">
            <button onClick={() => {}} className="btn-freedown"><span
              className="btntext">{translate("joincolart")}</span><img className="imgbtn" src={arrow_up}/></button>
          </a>
          <Linka to="#">
            <button onClick={() => {}} className="btn-disc"><span
              className="btntext">{translate("disc")}</span><img className="imgbtn" src={arrow_down}/></button>
          </Linka>
        </div>
      </div>
    </div>
  )
}

export default DeviceContainer