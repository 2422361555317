
// use-fetch-data.js
import { useEffect, useState} from 'react';
import axios from 'axios';


const useFetchData = (url) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const access_token =JSON.parse(localStorage.getItem("auth")).data.access_token;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(url,{  headers: {
            'Authorization': `Bearer ${access_token}`
          }});
        setData(response);
      } catch (error) {
        console.error(error)
      }
      setLoading(false);
    };

    fetchData().then(() => {});
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
