import React, { useContext, useEffect } from "react";
import SinglesContext from "../../../datamanager/contexts/singles";
import AlbumsContext from "../../../datamanager/contexts/albums";
import translate from "../../../i18n/translate";
import "./styles/dashboard.module.css"
import Loader from "../../../components/Loader";
import NoContent from "../../../components/NoContent";
import GreedUser from "./components/GreedUser";
import DataCardList from "../audio/component/DataCardList";
import DataChart from "./components/DataChart";
import {useLoadingContext} from "../../../datamanager/contexts/locals/Dashboard/loading";

function Dashboard() {

  // Get data from the global context
  const { singles } = useContext(SinglesContext);
  const { albums } = useContext(AlbumsContext);

  const { loading, handleSetLoading } = useLoadingContext();

  // UseEffect section
  useEffect(() => {
    if (singles && albums) {
      handleSetLoading(false)
    }
  }, [singles, albums])


  return (
      <>
        { loading ? <Loader /> :
          albums.count || singles.count ? (
            <div className="main-dash">
              <GreedUser />
              <div className="datas">
                <div className="main-side">
                  <span className="main-side-title">{translate('yourdata')}</span>
                  <DataCardList />
                  <div className="main-side-elt">
                    <span className="main-side-title">{translate('ouraudience')}</span>
                    <DataChart />
                  </div>
                </div>
              </div>
            </div>
        ) : <NoContent path={"/app/publish/select-content-type"} contentType={"album ou audio"} /> }
      </>
  );
}

export default Dashboard;
