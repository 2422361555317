import React from "react";
import LeftBarDatas1 from "./LeftBarDatas1";
import Comments from "../../pages/app/posts/components/Comments";

export default function SubMenuLeftBar({ subMenu }) {

  switch(subMenu){
    case 1:
      return <LeftBarDatas1 />;
    case 2:
      return <LeftBarDatas1 />;
    case 9:
      return <Comments />;
    default:
      return <></>;
  }
}