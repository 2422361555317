import DragOver from "../../assets/new_images/dragover.svg";
import { formatBytes, getAudioDuration } from "../../utils/functions";
import DelMusic from "../../assets/new_images/delmusic.svg";
import React from "react";
import { useSelectedAudio } from "../../datamanager/contexts/locals/publishAudio/selectedAudio";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import MusicInputSingle from "../MusicInputSingle";

export default function UploadSongListEltSingle({ audio, index, total, values, setFieldValue, handleChange }) {

  const { handleDeleteAudio } = useSelectedAudio();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id: String(index), // Ensure id is a string
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div
      key={index}
      className="publish_audio_form_medias__audios__item"
      ref={setNodeRef}
      {...attributes}
      style={style}
    >
      <img src={DragOver}
           {...listeners}
           ref={setNodeRef}
           alt={"drag"}
           className="publish_audio_form_medias__audios__item_drag"
      />
      <div className="publish_audio_form_medias__audios__item_song">
        <MusicInputSingle
          songTitle={audio.name}
          values={values}
          songSize={formatBytes(audio.size)}
          songTime={getAudioDuration(URL.createObjectURL(audio))}
          index={index}
          total={total}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
        />
      </div>
      <img src={DelMusic} alt={"del"} className="publish_audio_form_medias__audios__item_del" onClick={() => handleDeleteAudio(index)} />
    </div>
  );
}
