import styles from "./styles/posts.module.css"
import SearchBar from "./components/SearcBar";
import {SwitchUnderlinedProvider} from "../../../datamanager/contexts/locals/switchUnderlined";
import React from "react";
import MediaPostSwitch from "./components/MediaPostSwitch";

export default function Posts({  }){
  return (
    <div className={styles.posts}>
      <div className={styles.searchcontainer}>
        <SearchBar searchPlaceholder={"Search a Post"} />
      </div>
      <div className={styles.maintitle}>
        Vos publications dans l’actualité
      </div>
      <SwitchUnderlinedProvider>
        <MediaPostSwitch />
      </SwitchUnderlinedProvider>
    </div>
  );
}