import {useContext, useEffect, useState} from "react";
import { useFormik } from "formik";
import "../../../styles/App.css";
import {Link, useNavigate} from "react-router-dom";
import {
  CheckmarkCircle,
} from "react-ionicons";
import { HashLink as Linka } from "react-router-hash-link";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "yup-phone";
import { SpinnerCircularFixed } from "spinners-react";
import UserApi from "../../../api/users";
import toast from 'react-hot-toast'
import Cgu from "../../cgu";
import {SignupSchema} from "./SignUpSchema";
import {useLanguage} from "../../../datamanager/contexts/Language";
import logotypo from "../../../assets/new_images/logo_typo.svg";
import translate from "../../../i18n/translate";
import InputField from "../../../components/InputField";
import ConnexionUIRight from "../SignIn/ConnexionUIRight";
import SelectInputSex from "../../../components/SelectInputSex";
import InputPasswordField from "../../../components/InputPasswordField";
import {sleep} from "../../../utils/functions";
import PhoneInputField from "../../../components/PhoneInputField";
import ModalContext from "../../../datamanager/contexts/modalContext";

// const {conditionAccepted} = this.props.match.params;

function LogMusic({ cguAccepted }) {
  const [connected, setConnected] = useState(false);
  const [backErr, setBackErr] = useState(false);
  const { openModal } = useContext(ModalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [msg, setMsg] = useState(
    "Oupss...une erreur est survenue ! Verifier votre connection internet."
  );

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is logged in
    const token = localStorage.getItem("token");
    if (token) {
      // Redirect to the dashboard
      navigate("app/dashboard");
    } else {
      // Set isLoggedIn to false
      setIsLoggedIn(false);
    }
  }, [navigate]);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const token = localStorage.getItem("access_token");

    const userApi = new UserApi(token);

    values.password2 = values.password1;

    const response = await userApi.register(values);

    if (!response.data) {
      setMsg("Oupss...une erreur est survenue !");

      const errors = Object.values(response.error.response.data)

      errors.forEach(error => {
        toast.error(error[0], {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })

      await sleep(800);
      setIsSubmitting(false);
      setBackErr(true);
    } else {
      setConnected(true);

      localStorage.setItem("access_token", response.data.access_token);

      await sleep(1800);
      if (localStorage.getItem("access_token")) {
        document.location = "/app/dashboard";
        await sleep(1800).then(() => {
          openModal("SignUpPopUp", "Welcome to Colofol For Artists!");
        });

      }
    }
  };

  const verification = () => {
    return formik.isValid && cguAccepted; //conditionAccepted
  };

  const formik = useFormik({
    initialValues: {
      username: null,
      email: null,
      phone: null,
      password1: null,
      password2: null,
      sex: null,
      birthday: null,
      is_artist: true,
      is_blogger: false,
      is_evenementiel: false,
    },
    validationSchema: SignupSchema,
    onSubmit: handleSubmit,
  });

  const handleChangePhoneNumber = (
    phone,
    country,
    event,
    formattedValue
  ) => {
    formik.handleChange(event);
    formik.setFieldValue("phone", phone);
    formik.setFieldValue(
      "user_country",
      country.countryCode
    );
  }

  return (
    <div>
      <div className="loginpage">
        <div className="loginfond"></div>
        <div className="logincontent">
          <div className="firstside11">
            <div className="firstside2">
              <img alt={"label"} className="firstsideimage" src={logotypo}/>
              <form onSubmit={formik.handleSubmit}>
                <div className="firstsidecard1">
                  <p className="firstsidetitle11">{translate('enterdatas')}</p>

                  {
                    isSubmitting ? (
                      <>
                        { connected ? (
                          <div className={"checkmarkcircle"}><CheckmarkCircle
                            color={"#FF5620"}
                            title={"Connecte"}
                            height="50px"
                            width="50px"
                          /></div> ) : (
                          <>

                            <div><InputField
                              inputName="username"
                              inputLabel={translate('artistname')}
                              inputPlaceholder={"Enter your artist name"}
                              inputType="text"
                              onChange={formik.handleChange}
                              value={formik.values.username}
                            />
                              {formik.errors.username ? (<span className="emt">
                              {formik.errors.username}
                            </span>) : null}</div>

                            <div><InputField
                              inputName="email"
                              inputLabel={translate('artistemail')}
                              inputPlaceholder={"Enter your artist email"}
                              inputType="email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                              {formik.errors.email ? (<span className="emt">
                              {formik.errors.email}
                            </span>) : null}</div>

                            <div><InputPasswordField
                              inputName="password1"
                              inputLabel={translate('password')}
                              inputPlaceholder={""}
                              inputType="password"
                              onChange={formik.handleChange}
                              value={formik.values.password1}
                            />
                              {formik.errors.password1 ? (<span className="emt">
                              {formik.errors.password1}
                            </span>) : null}</div>

                            <div>
                              <PhoneInputField
                                value={formik.values.phone}
                                onChange={handleChangePhoneNumber}
                              />

                              {formik.errors.phone ? (
                                <span className="emt">{formik.errors.phone}</span>
                              ) : null}</div>

                            <div><InputField
                              inputName="birthday"
                              inputLabel={translate('birthday')}
                              inputPlaceholder={"Enter your birthdate"}
                              inputType="date"
                              onChange={formik.handleChange}
                              value={formik.values.birthday}
                            />
                              {formik.errors.birthday ? (
                                <span className="emt">
                                {formik.errors.birthday}
                              </span>
                              ) : null}</div>

                            <div><SelectInputSex fieldName={"Sex"} optionList={["male", "female", "other"]}
                                                 value={formik.values.sex} setFieldValue={formik.setFieldValue}/>
                              {formik.errors.sex ? (
                                <span className="emt">{formik.errors.sex}</span>
                              ) : null}</div>

                            <div>{JSON.stringify(formik.errors).length > 2 ? (<input
                              className="firstsidebutton-off"
                              type="submit"
                              value={connected ? "welcome back on colorfol for artists" : "Creating Account ..."}
                              onClick={() => {
                                setBackErr(false);
                              }}/>) : (<input
                              className="firstsidebutton"
                              type="submit"
                              value={connected ? "welcome back on colorfol for artists" : "Creating Account ..."}
                              onClick={() => {
                                setBackErr(false);
                              }}
                            />)}
                              <SpinnerCircularFixed
                                size={50}
                                thickness={121}
                                speed={150}
                                color="#FF5620"
                                secondaryColor="rgba(231, 208, 220, 1)"
                                className="firstside-spinner"
                              /></div>

                            <span className="firstsideconfidentialitetext11">{translate('byclicking1')}
                              <span className="firstsideconfidentialite">
                            {translate('byclicking2')}
                          </span>{translate('byclicking3')} Colorfol.
                        </span>
                          </>)
                        }
                      </>) :
                      backErr ? (
                        <>

                          <div><InputField
                            inputName="username"
                            inputLabel={translate('artistname')}
                            inputPlaceholder={"Enter your artist name"}
                            inputType="text"
                            onChange={formik.handleChange}
                            value={formik.values.username}
                          />
                            {formik.errors.username ? (<span className="emt">
                              {formik.errors.username}
                            </span>) : null}</div>

                          <div><InputField
                            inputName="email"
                            inputLabel={translate('artistemail')}
                            inputPlaceholder={"Enter your artist email"}
                            inputType="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />
                            {formik.errors.email ? (<span className="emt">
                              {formik.errors.email}
                            </span>) : null}</div>

                          <div><InputPasswordField
                            inputName="password1"
                            inputLabel={translate('password')}
                            inputPlaceholder={""}
                            inputType="password"
                            onChange={formik.handleChange}
                            value={formik.values.password1}
                          />
                            {formik.errors.password1 ? (<span className="emt">
                              {formik.errors.password1}
                            </span>) : null}</div>

                          <div>
                            <PhoneInputField
                              value={formik.values.phone}
                              onChange={handleChangePhoneNumber}
                            />

                            {formik.errors.phone ? (
                              <span className="emt">{formik.errors.phone}</span>
                            ) : null}</div>

                          <div><InputField
                            inputName="birthday"
                            inputLabel={translate('birthday')}
                            inputPlaceholder={"Enter your birthdate"}
                            inputType="date"
                            onChange={formik.handleChange}
                            value={formik.values.birthday}
                          />
                            {formik.errors.birthday ? (
                              <span className="emt">
                                {formik.errors.birthday}
                              </span>
                            ) : null}</div>

                          <div><SelectInputSex fieldName={"Sex"} optionList={["male", "female", "other"]}
                                               value={formik.values.sex} setFieldValue={formik.setFieldValue}/>
                            {formik.errors.sex ? (
                              <span className="emt">{formik.errors.sex}</span>
                            ) : null}</div>

                          {JSON.stringify(formik.errors).length > 2 ? (<input
                            className="firstsidebutton-off"
                            type="submit"
                            value={connected ? "welcome back on colorfol for artists" : "retry"}
                            onClick={() => {
                              setBackErr(false);
                            }}/>) : (<input
                            className="firstsidebutton"
                            type="submit"
                            value={connected ? "welcome back on colorfol for artists" : "retry"}
                            onClick={() => {
                              setBackErr(false);
                            }}
                          />)}

                          <span className="firstsideconfidentialitetext11">{translate('byclicking1')}
                            <span className="firstsideconfidentialite">
                            {translate('byclicking2')}
                          </span>{translate('byclicking3')} Colorfol.
                        </span>

                        </>) : (
                        <>
                          <div><InputField
                            inputName="username"
                            inputLabel={translate('artistname')}
                            inputPlaceholder={"Enter your artist name"}
                            inputType="text"
                            onChange={formik.handleChange}
                            value={formik.values.username}
                          />
                            {formik.errors.username ? (<span className="emt">
                              {formik.errors.username}
                            </span>) : null}</div>

                          <div><InputField
                            inputName="email"
                            inputLabel={translate('artistemail')}
                            inputPlaceholder={"Enter your artist email"}
                            inputType="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />
                            {formik.errors.email ? (<span className="emt">
                              {formik.errors.email}
                            </span>) : null}</div>

                          <div><InputPasswordField
                            inputName="password1"
                            inputLabel={translate('password')}
                            inputPlaceholder={""}
                            inputType="password"
                            onChange={formik.handleChange}
                            value={formik.values.password1}
                          />
                            {formik.errors.password1 ? (<span className="emt">
                              {formik.errors.password1}
                            </span>) : null}</div>

                          <div>
                            <PhoneInputField
                              value={formik.values.phone}
                              onChange={handleChangePhoneNumber}
                            />

                            {formik.errors.phone ? (
                              <span className="emt">{formik.errors.phone}</span>
                            ) : null}</div>

                          <div><InputField
                            inputName="birthday"
                            inputLabel={translate('birthday')}
                            inputPlaceholder={"Enter your birthdate"}
                            inputType="date"
                            onChange={formik.handleChange}
                            value={formik.values.birthday}
                          />
                            {formik.errors.birthday ? (
                              <span className="emt">
                                {formik.errors.birthday}
                              </span>
                            ) : null}</div>

                          <div><SelectInputSex fieldName={"Sex"} optionList={["male", "female", "other"]}
                                               value={formik.values.sex} setFieldValue={formik.setFieldValue}/>
                            {formik.errors.sex ? (
                              <span className="emt">{formik.errors.sex}</span>
                            ) : null}</div>

                          {JSON.stringify(formik.errors).length > 2 ? (<input
                            className="firstsidebutton-off"
                            type="submit"
                            value={connected ? "welcome back on colorfol for artists" : "Create"}
                            onClick={() => {
                              setBackErr(false);
                            }}/>) : (<input
                            className="firstsidebutton"
                            type="submit"
                            value={connected ? "welcome back on colorfol for artists" : "Create"}
                            onClick={() => {
                              setBackErr(false);
                            }}
                          />)}

                          <span className="firstsideconfidentialitetext11">{translate('byclicking1')}
                            <span className="firstsideconfidentialite">
                            {translate('byclicking2')}
                          </span>{translate('byclicking3')} Colorfol.
                        </span>
                        </>)
                  }
                  <p className="firstsidenoaccount11">{translate('artistyet')} <Link to={"/app/access"}
                                                                                     className="firstsidecreate"> {translate('connect')}</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <ConnexionUIRight/>
        </div>
      </div>
    </div>
  );
}

export default LogMusic;
