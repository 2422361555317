import Stepper from "../../../../components/Stepper";
import React from "react";
import {uploadSteps} from "../../../../utils/Constants";
import PreviewRightSide from "../../../../components/PublishAudio/previewRightSide";
import StepperActions from "./StepperActions";

export default function UploadProcessEnd({ values, errors, handleSubmit, isValid, handleReset}){

  return (
    <div className="publish_audio_form">
      <PreviewRightSide values={values} />
      <div className="publish_audio_form_stepper">
        <Stepper steps={uploadSteps} />
        <StepperActions values={values} errors={errors} handleSubmit={handleSubmit} isValid = {isValid}  handleReset={handleReset}/>
      </div>
    </div>
  );
}