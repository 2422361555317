import translate from '../../../i18n/translate'
import '../../App.css';
import styles from '../styles/footer.module.css'
import logotyp from "../../../assets/new_images/logo_typo.svg"
import ordi from "../../../assets/new_images/ordi.svg"
import playstore from "../../../assets/new_images/playstore.svg"
import applestore from "../../../assets/new_images/applestore.svg"
import facebook from "../../../assets/new_images/facebook.svg"
import x from "../../../assets/new_images/x.svg"
import instagram from "../../../assets/new_images/instagram.svg"
import { HashLink as Linka } from 'react-router-hash-link';
import {useLanguage} from "../../../datamanager/contexts/Language";
import {LOCALES} from "../../../i18n";
import english from "../../../assets/new_images/english.svg";
import french from "../../../assets/new_images/french.svg";
import arabic from "../../../assets/new_images/arabic.svg";

const Footer = () => {

  const { language, languageList, updateHotLanguage } = useLanguage();


  const selectedFlag = (() => {
    switch (language) {
      case LOCALES.ENGLISH:
        return english;
      case LOCALES.FRANCAIS:
        return french;
      case LOCALES.ARABE:
        return arabic;
      default:
        return null; // Handle default case if necessary
    }
  })();

  return (
    <div className="fulll">
      <div className={`foooter ${styles.footerContainer}`}>

        <div className="fond6"></div>

        <div className={`foooter1 ${styles.footerContainer1}`}>

          <div className={styles.footerBlock}>

            <span className='ftr-logoo'>
              <img className="ftr-logoo-img" src={logotyp} alt="floatings-icons"/>
            </span>

          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-contentt ${styles.footerContent}`}>
              <h3>{translate('ft_fmt')}</h3>

              <ul>
                <li><a href="#3">{translate('getstart')}</a></li>
                <li><a href="#3">{translate('downapp')}</a></li>
                <li><a href="#3">{translate('subpricing')}</a></li>
                <li><a href="#3">{translate('help')}</a></li>
              </ul>
            </div>
          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-contentt ${styles.footerContent}`}>
              <h3>{translate('diss')}</h3>

              <ul>
                <li><a href="#3">{translate('colart')}</a></li>
                <li><a href="#3">{translate('fmt_ti')}</a></li>
                <li><a href="#3">{translate('fmt_fiii')}</a></li>
                <li><a href="#3">{translate('fmt_fii')}</a></li>
              </ul>
            </div>
          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-contentt ${styles.footerContent}`}>
              <h3>{translate('ourcompany')}</h3>

              <ul>
                <li><a href="#3">{translate('waw')}</a></li>
                <li><a href="#3">{translate('ov')}</a></li>
                <li><a href="#3">{translate('ot')}</a></li>
              </ul>

              <h3>{translate('fu')}</h3>

              <div className="follow">
                <a href="instagram">
                  <img alt={"tooltip"} src={instagram} className="links1"/>
                </a>
                <a href="instagram">
                  <img alt={"tooltip"} src={x} className="links1"/>
                </a>
                <a href="instagram">
                  <img alt={"tooltip"} src={facebook} className="links1"/>
                </a>
              </div>

            </div>
          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-contentt2 ${styles.footerContent}`}>
              <h3>{translate('ft_smt')}</h3>

              <ul>
                <li><a href="#3"><span className="cont">{translate('smt_fi')}</span></a></li>
                <li><a href="#3">{translate('smt_si')}</a></li>
                <li><a href="#3">{translate('smt_ti')}</a></li>
                <li><a href="#3"><span className="cont">{translate('smt_foi')}</span></a></li>
                <li><a href="#3">{translate('smt_fii')}</a></li>
                <li><a href="#3">{translate('smt_sii')}</a></li>
                <li><a href="#3"><span className="cont">{translate('smt_sei')}</span></a></li>
                <li><a href="#3">{translate('smt_hui')}</a></li>
                <li><a href="#3">{translate('smt_nei')}</a></li>
              </ul>
            </div>
          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-contentt3 ${styles.footerContent}`}>
              <img alt={"tooltip"} src={ordi} className="links5"/>
              <a href="download"><img src={applestore} className="links3"/></a>
              <a href="download"><img src={playstore} className="links"/></a>
            </div>
          </div>
        </div>

        <div className="copywright">
          <div className="legals">
            <Linka to="cgu"><span className="terms">{translate('terms')}</span></Linka>
            <Linka to="privacy-policy"><span className="privacy">{translate('privacy')}</span></Linka>
            <div className="languages">
              <div className="langg"><img alt={"lang"} className="langgimg" src={selectedFlag}/></div>
              <select className='lsdro1' value={language} onChange={updateHotLanguage} id="languages">
                {
                  languageList.map((item) => (
                    <option key={item.code} value={item.value}>
                      {item.code}
                    </option>
                  ))
                }
              </select>
            </div>
            <span className="cpwright">{translate('copyright')}</span>
          </div>
        </div>

      </div>
    </div>
  )
}
export default Footer