import up from "../../../../assets/new_images/Up.svg";
import down from "../../../../assets/new_images/Down.svg";
import React from "react";
import {formatNumber} from "../../../../utils/FormatValues";
import SingleEltCover from "./SingleEltCover";

export default function SingleElt({ single, evolution, index }) {
  return (
    <div className="topfan-titles">
      <span className="topfan-r">
        <span className="text-gray-600">{index + 1}</span>
        <SingleEltCover single={single}  />
        <span className="topfan-r1">
          <span>{single.title}</span>
        </span>
      </span>
      <span className="text-lg text-gray-600 font-bold">{formatNumber(single.nb_of_listening)}</span>
      <span className="text-lg text-gray-600 font-bold">+10%</span>
      <span className="topfan-r">{index}<img alt={"evo"} className="topsingvalimg" src={evolution === "up" ? up : down}/></span>
    </div>
  );
}