import CloseIcon from "../../../../assets/new_images/close_.svg";
import Certif from "../../../../assets/new_images/CircleWavyWarning.svg";
import RepeatOnce from "../../../../assets/new_images/RepeatOnce.svg";
import ControlBack from "../../../../assets/new_images/controlBack.svg";
import controlPlay from "../../../../assets/new_images/controlPlay.svg";
import ControlForward from "../../../../assets/new_images/controlFoward.svg";
import OrangeHeart from "../../../../assets/new_images/orangeHeart.svg";
import SpeakerNone from "../../../../assets/new_images/SpeakerSimpleNone.svg";
import SpeakerHigh from "../../../../assets/new_images/SpeakerSimpleHigh.svg";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import CurrentUserContext from "../../../../datamanager/contexts/currentUser";
import {useSelectedImage} from "../../../../datamanager/contexts/locals/publishAudio/selectedImage";
import {useMetaData} from "../../../../datamanager/contexts/locals/publishAudio/metaData";
import {useStoreData} from "../../../../datamanager/contexts/StoreDatas";
import {useSelectedAudio} from "../../../../datamanager/contexts/locals/publishAudio/selectedAudio";
import translate from "../../../../i18n/translate";
import {useCurrentPage} from "../../../../datamanager/contexts/locals/publishAudio/currentPage";
import {useUploadSpeed} from "../../../../datamanager/contexts/locals/publishAudio/uploadSpeed";
import {useBackErr} from "../../../../datamanager/contexts/locals/publishAudio/backErr";
import {useUploadPercentage} from "../../../../datamanager/contexts/locals/publishAudio/uploadPercent";
import {useMsg} from "../../../../datamanager/contexts/locals/publishAudio/msg";
import {transformUrl} from "../../../../utils/functions";
import resizeImage from "../../../../components/resizeImage";

export default function Preview({ values, handleReset }){

  const { currentUser: user } = useContext(CurrentUserContext);
  const { selectedImage } = useSelectedImage();

  const { handleClearMetaData } = useMetaData();
  const { handleUpdateStoreData } = useStoreData();
  const { handleSetSelectedAudio } = useSelectedAudio();
  const { handleSetSelectedImage } = useSelectedImage();
  const { handleSetCurrentPage } = useCurrentPage();
  const { handleSetUploadSpeed } = useUploadSpeed();
  const { handleSetBackErr } = useBackErr();
  const { handleSetUploadPercentage } = useUploadPercentage();
  const { handleSetMsg } = useMsg();

  const handleCancelProcess = () => {
    handleUpdateStoreData(null);
    handleSetSelectedAudio(null);
    handleSetSelectedImage(null);
    handleSetUploadPercentage(0);
    handleSetUploadSpeed(0);
    handleClearMetaData();
    handleSetBackErr(false);
    handleSetMsg(null);
    handleSetCurrentPage(1);
    handleReset();
  }

  return (
    <div className="publish_audio__preview">
      <div className="publish_audio__preview__fond"></div>
      <div className="publish_audio__preview__container">
        <Link to="/app/dashboard" onClick={handleCancelProcess} className="publish_audio_form_stepper__actions__canceel">
          {translate("Cancel")}
            <img alt={"label"} src={CloseIcon}
                 className="publish_audio_form_stepper__actions__publish__icon"/>
        </Link>
        <div className="publish_audio__preview__container__title">{translate("previewOnMobilePlayer")}
        </div>
        <div className="publish_audio__preview__container__img">
          {selectedImage !== null ?
            <img className="publish_audio__preview__container__img_img" src={URL.createObjectURL(selectedImage)}
                 alt="not displaying"/> : null}
        </div>
        <div className="publish_audio__preview__subcontainer">
          <span className="publish_audio__preview__container__subtitle">{values.title && values.title}</span>
          <div className="publish_audio__preview__subcontainer__container">
            <img alt={"label"} src={transformUrl(user.cover, "sml")} className="publish_audio__preview__subcontainer__img"/>
            <span className="publish_audio__preview__subcontainer__text">{user.alias}</span>
            <img alt={"label"} src={Certif} className="publish_audio__preview__subcontainer__certif"/>
          </div>
          <div className="publish_audio__preview__subcontainer__progress1">
            <div className="publish_audio__preview__subcontainer__progress2"></div>
          </div>
          <div className="publish_audio__preview_times">
            <span className="publish_audio__preview_times_start">0:24</span>
            <span className="publish_audio__preview_times_start">-5:00</span>
          </div>
          <div className="publish_audio_control">
            <img alt="control element" className="publish_audio_control_elt1" src={RepeatOnce}/>
            <img alt="control element" className="publish_audio_control_elt2" src={ControlBack}/>
            <img alt="control element" className="publish_audio_control_elt2" src={controlPlay}/>
            <img alt="control element" className="publish_audio_control_elt2" src={ControlForward}/>
            <img alt="control element" className="publish_audio_control_elt1" src={OrangeHeart}/>
          </div>
          <div className="publish_audio__preview__subcontainer__progress3">
            <img alt={"label"} src={SpeakerNone}/>
            <div className="publish_audio__preview__subcontainer__progress4"></div>
            <img alt={"label"} src={SpeakerHigh}/>
          </div>
        </div>
      </div>
    </div>
  );
}