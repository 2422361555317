import translate from '../../../i18n/translate'
import '../../App.css';
import styles from '../styles/why.module.css'
import fondd1 from "../../../assets/new_images/fondd1_.png"
import fondd2 from "../../../assets/new_images/fondd2.svg"
import fondd3 from "../../../assets/new_images/fondd3.svg"
import fondd4 from "../../../assets/new_images/fondd4_.png"
import InfiniteCarousel from "./InfiniteCarousel";
import '../styles/carousel.module.css'

const WhySectionComponent = () => {

  const items = [
    <div className="car-elt">
      <div className="placeholder">
        <img className="carouimg3" src={fondd1}/>
      </div>
      <span className='carouspan1'>{translate('disc_buy')}<p className="subcarouspan1">{translate('subdisc_buy')}</p></span>
    </div>,
    <div className="car-div"></div>,
    <div className="car-elt">
      <div className="placeholder"><img className="carouimg2" src={fondd2}/></div>
      <span className='carouspan1'>{translate('play_off')}<p
        className="subcarouspan1">{translate('subplay_off')}</p></span>
    </div>,
    <div className="car-div"></div>,
    <div className="car-elt">
      <div className="placeholder"><img className="carouimg3" src={fondd3}/></div>
      <span className='carouspan1'>{translate('playlist_fy')}<p className="subcarouspan1">{translate('subplaylist_fy')}</p></span>
    </div>,
    <div className="car-div"></div>,
    <div className="car-elt">
      <div className="placeholder"><img className="carouimg22" src={fondd4}/></div>
      <span className='carouspan1'>{translate('pass_event')}<p className="subcarouspan1">{translate('subpass_event')}</p></span>
    </div>,
    <div className="car-div"></div>,
  ]

  return (
      <div className="fulll">
        <div className={`why-head ${styles.whyHeader}`}>
          <span className='why-title'>{translate("hqa")}</span>
          <p className="sub-why-title">{translate("subhqa")}</p>
        </div>

        <InfiniteCarousel className="infinite-carousel" cards={items}/>
      </div>
  )
}

export default WhySectionComponent